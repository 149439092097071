import { useState } from 'react';
import { Outlet } from 'react-router-dom';

import { Button } from 'components/button/Button';
import { Modal } from 'components/modal/Modal';

import styles from './routes.module.scss';

export function PrivateRoutes() {
  const [isAgreed, setIsAgreed] = useState(() => {
    const savedValue = sessionStorage.getItem('d8x_terms_agreement');
    if (!savedValue) return null;
    return savedValue === 'true';
  });

  if (isAgreed === null) {
    return (
      <>
        <Outlet />
        <Modal text="Disclaimer D8X Protocol Software">
          <div className={styles.modalContent}>
            <p>
              <b>No control:</b> Neither Quantena AG nor any individual or group of D8X project team members,
              contributors, D8X token holders or any other third-party has control of or is the operator of the set of
              blockchain-based smart contracts that together constitute the D8X protocol, the D8X token and any related
              technology (the "<b>D8X Protocol Software</b>"). Your access and use of the D8X Protocol Software are not
              subject to the{' '}
              <a href="/20231029 D8X Terms of Service.pdf" target="_blank" rel="noopener noreferrer">
                Terms of Service
              </a>
              . Any services offered by Quantena AG are strictly limited to the Services as defined in the{' '}
              <a href="/20231029 D8X Terms of Service.pdf" target="_blank" rel="noopener noreferrer">
                Terms of Service
              </a>
              , and such Services are distinctly separate from the D8X Protocol Software. Any use of the terms 'we',
              'our' etc., is for semantic purposes only and not to be understood as an assumption of ownership or
              control of the D8X Protocol Software by Quantena AG or any individual or group of D8X project team
              members, contributors, or D8X token holders.
            </p>
            <p>
              <b>Assumption of risks and no liability:</b> If you access or use the D8X Protocol Software, you
              understand and acknowledge that blockchain and DeFi are novel and experimental technologies, that you do
              so at your own risk, and that you have read and understood the full{' '}
              <a href="/20231029 D8X Protocol Disclaimer.pdf" target="_blank" rel="noopener noreferrer">
                D8X Protocol Software Disclaimer
              </a>
              . The D8X Protocol Software is developed and deployed 'as is' and without any guarantees whatsoever.{' '}
            </p>
            <p>
              <b>Decentralized governance:</b> Decisions regarding the D8X Protocol Software are governed by a dispersed
              group of supporters, contributors, and participants through the technical governance utility of the D8X
              token. You can participate in the governance process in accordance with the technical functionalities and
              parameters as summarized{' '}
              <a
                href="https://d8x.gitbook.io/d8x/d8x-token/utility#protocol-governance"
                target="_blank"
                rel="noopener noreferrer"
              >
                here
              </a>
              , and/or join the D8X social media channels and contribute to the conversation.{' '}
            </p>
            <p>
              <b>Independent frontends:</b> The D8X Protocol Software can be accessed and used through a variety of user
              interfaces offered by independent third-parties, as well as through direct interaction with the smart
              contracts without the use of any frontend whatsoever. Quantena AG does not offer, maintain, operate,
              recommend, endorse, or control any user interface to the D8X Protocol Software.
            </p>
            <p>
              <b>No partnership:</b> You do not agree to and do not state your will to enter into or create a simple
              partnership, joint venture, or similar sort of legal or factual partnership by entering into the Terms of
              Service, by accessing or using the D8X Protocol Software, by interacting with the D8X token and/or by
              participating in the D8X Protocol Software governance.
            </p>
            <p>
              <b>Release of claims:</b> By accessing or using the D8X Protocol Software, you hereby, to the maximum
              extent permissible under applicable law, release all present and future claims against Quantena AG, and
              against any individual or group of D8X project team members, contributors, or D8X token holders related to
              your use of the D8X Protocol Software, the D8X token, the D8X governance mechanism, and any other facet of
              the D8X Protocol Software.
            </p>
            <p>
              <b>Applicable Laws:</b> The laws and regulations that apply to your use of the D8X Protocol Software may
              vary based upon the jurisdiction in which you are domiciled, located, operate out of, etc. The Company
              assumes no responsibility that your use of the D8X Protocol Software is in compliance with applicable laws
              and regulations. You are strongly encouraged to discuss your use of the D8X Protocol Software with your
              legal counsel.
            </p>
            <p>
              <b>No Prohibited Jurisdiction:</b> You are not accessing the D8X Protocol Software from a Prohibited
              Jurisdiction (as defined under provision 3.2 of the{' '}
              <a href="/20231029 D8X Terms of Service.pdf" target="_blank" rel="noopener noreferrer">
                Terms of Service
              </a>
              ).
            </p>
            <p>
              For more information, please refer to the full{' '}
              <a href="/20231029 D8X Protocol Disclaimer.pdf" target="_blank" rel="noopener noreferrer">
                D8X Protocol Software Disclaimer
              </a>
              .
            </p>
          </div>
          <div className={styles.buttons}>
            <Button
              variant="primary"
              hasArrow={false}
              className={styles.acceptButton}
              onClick={() => {
                sessionStorage.setItem('d8x_terms_agreement', 'true');
                return setIsAgreed(true);
              }}
            >
              Agree
            </Button>
            <Button
              variant="secondary"
              hasArrow={false}
              className={styles.acceptButton}
              onClick={() => setIsAgreed(false)}
            >
              Disagree
            </Button>
          </div>
        </Modal>
      </>
    );
  }

  return isAgreed ? (
    <Outlet />
  ) : (
    <h2 className={`h2 ${styles.refuseMessage}`}>
      YOU DID NOT AGREE TO THE TERMS OF SERVICE. YOU MUST IMMEDIATELY EXIT THE WEBSITE AND STOP ACCESSING OR USING THE
      SERVICES.
    </h2>
  );
}
